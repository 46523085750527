import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { AddressModel } from "../../../record/core/domain/address/AddressModel";
/// <reference types="google.maps" />

/**
 * @file google-maps.service.ts
 * @description This service provides methods to load the Google Maps JavaScript API and interact with Google Maps services such as AutocompleteService and PlacesService.
 */

@Injectable({
    providedIn: "root",
})
/**
 * Service to interact with the Google Maps JavaScript API, including loading the API script,
 * and providing access to the AutocompleteService and PlacesService.
 */
export class GoogleMapsService {
    private scriptLoaded = false;
    private autocompleteService: google.maps.places.AutocompleteService | null =
        null;
    private placesService: google.maps.places.PlacesService | null = null;

    /**
     * Creates an instance of GoogleMapsService.
     */
    constructor() {}

    /**
     * Loads the Google Maps JavaScript API script dynamically.
     *
     * @returns {Promise<void>} A promise that resolves when the script is successfully loaded,
     * or rejects if there is an error loading the script.
     *
     * @remarks
     * If the script is already loaded (`this.scriptLoaded` is true), the promise resolves immediately.
     * The script is appended to the document body with `async` and `defer` attributes set to true.
     * The API key and libraries are specified in the script's `src` URL.
     *
     * @example
     * ```typescript
     * this.loadGoogleMaps().then(() => {
     *   // Google Maps API is loaded and ready to use
     * }).catch((error) => {
     *   console.error('Error loading Google Maps API:', error);
     * });
     * ```
     */
    loadGoogleMaps(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.scriptLoaded) {
                resolve();
                return;
            }

            const script = document.createElement("script");
            script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.routes.mapsApi.key}&libraries=places`;
            script.async = true;
            script.defer = true;
            script.onload = () => {
                this.scriptLoaded = true;
                resolve();
            };
            script.onerror = (error) => reject(error);

            document.body.appendChild(script);
        });
    }

    /**
     * Retrieves the Google Maps AutocompleteService instance.
     * If the instance does not already exist and the Google Maps API is available,
     * it initializes a new AutocompleteService instance.
     *
     * @returns {google.maps.places.AutocompleteService} The AutocompleteService instance.
     */
    private getAutocompleteService(): google.maps.places.AutocompleteService {
        if (!this.autocompleteService && window.google) {
            this.autocompleteService =
                new google.maps.places.AutocompleteService();
        }
        return this.autocompleteService!;
    }

    /**
     * Retrieves the Google PlacesService instance. If the instance does not exist,
     * it initializes it using a newly created div element (not visible).
     *
     * @returns {google.maps.places.PlacesService} The Google PlacesService instance.
     */
    private getPlacesService(): google.maps.places.PlacesService {
        if (!this.placesService && window.google) {
            const map = document.createElement("div");
            this.placesService = new google.maps.places.PlacesService(map);
        }
        return this.placesService!;
    }

    /**
     * Retrieves place predictions based on the input string using the Google Maps AutocompleteService.
     *
     * @param input - The input string to search for place predictions.
     * @returns A promise that resolves to an array of AddressModel instances containing place predictions.
     *
     * @throws Will reject the promise if the AutocompleteService is not initialized or if fetching predictions fails.
     */
    getPlacePredictions(input: string): Promise<AddressModel[]> {
        return new Promise((resolve, reject) => {
            const autocompleteService = this.getAutocompleteService();
            if (!autocompleteService) {
                return reject("AutocompleteService is not initialized.");
            }

            autocompleteService.getPlacePredictions(
                { input },
                (predictions, status) => {
                    if (
                        status === google.maps.places.PlacesServiceStatus.OK &&
                        predictions
                    ) {
                        const addressDataArray = predictions.map(
                            (prediction) => {
                                const addressData = new AddressModel();
                                addressData.placeId = prediction.place_id || "";
                                addressData.addressString =
                                    prediction.description || "";
                                return addressData;
                            }
                        );
                        resolve(addressDataArray);
                    } else {
                        reject(`Failed to fetch predictions: ${status}`);
                    }
                }
            );
        });
    }

    /**
     * Retrieves detailed information about a place using its place ID.
     *
     * @param placeId - The unique identifier of the place to retrieve details for.
     * @returns A promise that resolves to an `AddressModel` containing the place details,
     *          or rejects with an error message if the details could not be fetched.
     *
     * @throws Will reject with an error message if the PlacesService is not initialized
     *         or if the place details could not be fetched.
     */
    getPlaceDetails(placeId: string): Promise<AddressModel> {
        return new Promise((resolve, reject) => {
            const placesService = this.getPlacesService();
            if (!placesService) {
                return reject("PlacesService is not initialized.");
            }

            placesService.getDetails({ placeId }, (place, status) => {
                if (
                    status === google.maps.places.PlacesServiceStatus.OK &&
                    place
                ) {
                    const addressData = new AddressModel();
                    addressData.placeId = place.place_id || "";
                    addressData.addressString = place.formatted_address || "";
                    addressData.lat = place.geometry?.location?.lat() || 0;
                    addressData.lng = place.geometry?.location?.lng() || 0;
                    resolve(addressData);
                } else {
                    reject(`Failed to fetch place details: ${status}`);
                }
            });
        });
    }
}
